import { TextField } from "@mui/material"
import React from "react"


const SGTextfield = props => (
  <TextField
    variant="standard"
    sx={{
      "& label.Mui-focused": {
        color: "#00afc5",
      },
      "& .MuiInput-underline:after": {
        borderBottomColor: "#00afc5",
      },
      "& .MuiOutlinedInput-root": {
        "&.Mui-focused fieldset": {
          borderColor: "#00afc5",
        },
      },
    }}
    {...props}
  />
)

export default SGTextfield
